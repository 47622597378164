import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import JSAlert from 'js-alert';

const body = document.querySelector('body');
const menuToggle = document.getElementById('menu-toggle');
const menu = document.getElementById('mobile-menu');
const firstMenuItem = document.getElementById('first-menu-item');

const openMenu = () => {
  body.classList.add('disable-scroll');
  menu.classList.add('active');
  firstMenuItem.focus();
};

const closeMenu = () => {
  body.classList.remove('disable-scroll');
  menu.classList.remove('active');
  menuToggle.focus();
};

const toggleMenu = () => {
  if (menu.classList.contains('active')) {
    closeMenu();
  } else {
    openMenu();
  }
};

AOS.init({
  duration: 500,
});
menuToggle.addEventListener('click', toggleMenu);

const name = document.getElementById('name-input');
const email = document.getElementById('email-input');
const submit = document.getElementById('submit');

submit.addEventListener('submit', async (e) => {
  e.preventDefault();
  try {
    submit.value = 'Sending...';
    const response = await axios.post(
      'https://kgljrnjrgsmrz3q6lzkj.sabikuk.com/api/v1/waitlist/send',
      {
        name: name.value,
        email: email.value,
      }
    );
    JSAlert.alert(
      'Success',
      'You have been added to the waitlist, Thank you for your interest in Sabikuk!'
    );
    name.value = '';
    email.value = '';
  } catch (error) {
    JSAlert.alert('Error', error?.response?.data?.message, '');
  }
});
